import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';

import { Link as ScrollLink } from 'react-scroll';

import DataContext from './Context/Data';

import Section from './Section';
import { LayerBackground, LayerImage } from './Layer';
import SiteWidth from './SiteWidth';
import { Wrapper } from './Content';
import Space from './Space';

import Logo from './Logo';
import Navigation from './Navigation';

import { Paragraph, Link } from './Text';

import Parallax from './Parallax';

import srcCover from '../assets/images/cover.jpg';
import srcScroll from '../assets/images/scroll.svg';

const scrollAnimation = keyframes`
  0%   { transform: translateY(0%); }
  100% { transform: translateY(-40%); }
`;

const ScrollButton = styled(Link)`
  position: absolute;
  display: flex;
  align-items: flex-end;
  bottom: 30px;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translateX(-50%);
`;

const Scroll = styled.img`
  width: 100%;
  animation:
    ${scrollAnimation}
    800ms infinite alternate
    cubic-bezier(0.165, 0.840, 0.440, 1.000);
`;

const BannerSection = styled(Section)`
  height: 100vh;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    height: 100%;
    padding-top: 140px;
    padding-bottom: 140px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    height: 100%;
    padding-top: 28px;
    padding-bottom: 20px;
    ${ScrollButton} { display: none; }
  }
`;

const BannerParallax = styled(Parallax)`
  &, & > div {
    position: absolute;
    top: -10%;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
`;

const Tagline = styled(Paragraph)`
  height: 0px;
  transform: translateY(-60px);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white.base};
`;

const StyledWrapper = styled(Wrapper)`
  [data-size="L"] { display: block; }
  [data-size="M"] { display: none; }
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    [data-size="L"] { display: none; }
    [data-size="M"] { display: block; }
    ${Tagline} { display: none; }
  }
`;

export default function SectionBanner() {
  const {
    meta: { tagline },
    content: { sectionBanner: { navigation } },
  } = useContext(DataContext);

  return (
    <BannerSection>
      <BannerParallax depth={0.2}>
        <LayerImage src={srcCover} />
      </BannerParallax>
      <LayerBackground
        background={`linear-gradient(${[
          'to bottom',
          'rgba(224, 138, 0, 0.38) 0%',
          'rgba(195, 5, 101, 0.59) 100%',
        ].join(', ')})`}
      />
      <SiteWidth>
        <StyledWrapper align="center">
          <Logo size="L" />
          <Logo size="M" />
          <Tagline>
            {`- ${tagline} -`}
          </Tagline>
          <Space size="10px" />
          <Navigation items={navigation} />
        </StyledWrapper>
      </SiteWidth>
      <ScrollButton
        component={ScrollLink}
        to="o-nama"
        offset={0}
        smooth="easeOutQuart"
        underline={false}
        duration={1000}
      >
        <Scroll src={srcScroll} />
      </ScrollButton>
    </BannerSection>
  );
}
