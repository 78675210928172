import React from 'react';
import styled from 'styled-components';

const HtmlHTML = ({
  as: As = 'div',
  html,
  ...props
}) => (
  <As {...props} dangerouslySetInnerHTML={{ __html: html }} />
);

const HTML = styled(HtmlHTML)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  > *:first-child {
    margin: 0px;
  }
  h1, h2, h3, h4, h5, h6, p, ul, ol {
    margin-top: 15px;
    line-height: 1.5;
  }
  h1 {
    font-size: 26px;
    font-weight: 700;
  }
  ul, ol {
    padding-inline-start: 20px;
  }
  li {
    padding: 5px;
  }
  li {
    list-style-type: disc;
  }
`;

export default HTML;
