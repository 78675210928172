import React from 'react';

import styled, { css } from 'styled-components';

const SpaceHTML = ({
  as: As = 'div',
  horizontal,
  vertical,
  size,
  ...props
}) => (
  <As {...props} />
);

const Space = styled(SpaceHTML)`
  display: flex;
  ${({ size, vertical }) => (
    vertical
    ? css`width: ${size};`
    : css`height: ${size};`
  )}
`;

export default Space;
