import React from 'react';

import App from '../components/App';
import PageHome from '../components/PageHome';

export default function Page() {
  return (
    <App>
      <PageHome />
    </App>
  );
};
