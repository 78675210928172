/* eslint-disable max-len */

import { FontFaces } from './FontFace';

import Roboto400 from '../../assets/fonts/Roboto/Roboto-Regular.ttf';
import Roboto400Italic from '../../assets/fonts/Roboto/Roboto-Italic.ttf';
import Roboto700 from '../../assets/fonts/Roboto/Roboto-Bold.ttf';
import Roboto700Italic from '../../assets/fonts/Roboto/Roboto-BoldItalic.ttf';

import Sacramento400 from '../../assets/fonts/Sacramento/Sacramento-Regular.ttf';

export const Roboto = FontFaces.create(
  [
    { url: Roboto400, weight: 400 },
    { url: Roboto700, weight: 700 },
  ],
  { name: 'Roboto' },
);

export const RobotoItalic = FontFaces.create(
  [
    { url: Roboto400Italic, weight: 400 },
    { url: Roboto700Italic, weight: 700 },
  ],
  { name: 'Roboto' },
);

export const Sacramento = FontFaces.create(
  [
    { url: Sacramento400, weight: 400 },
  ],
  { name: 'Sacramento' },
);
