import React, { useContext } from 'react';

import DataContext from './Context/Data';

import Section from './Section';
import SiteWidth from './SiteWidth';
import {
  Wrapper,
  Title,
  Divider,
  Content,
  HTML,
} from './Content';

export default function SectionSaradnici() {
  const {
    content: {
      sectionSaradnici: data,
    },
  } = useContext(DataContext);

  return (
    <Section id={data.id}>
      <SiteWidth>
        <Wrapper closing>
          <Title>{data.title}</Title>
          <Divider />
          <Content>
            <HTML html={data.html} />
          </Content>
        </Wrapper>
      </SiteWidth>
    </Section>
  );
}
