import React from 'react';
import styled from 'styled-components';

import Section from './Section';
import { LayerColor } from './Layer';
import SiteWidth from './SiteWidth';
import { Wrapper } from './Content';

const StyledWrapper = styled(Wrapper)`
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${({ theme }) => theme.colors.white.base};
  font-size: 14px;
  letter-spacing: 0.1em;
`;

export default function SectionFooter() {
  return (
    <Section>
      <LayerColor color="#ccc" />
      <SiteWidth>
        <StyledWrapper align="center">
          {`Copyright © ${(new Date()).getFullYear()} - Vita Longa`}
        </StyledWrapper>
      </SiteWidth>
    </Section>
  );
}
