import React, { useContext } from 'react';

import DataContext from './Context/Data';

import Page from './Page';

import SectionBanner from './SectionBanner';
import SectionONama from './SectionONama';
import SectionSaradnici from './SectionSaradnici';
import SectionKontakt from './SectionKontakt';
import SectionFooter from './SectionFooter';

import Meta from './Meta';

export default function PageHome() {
  const { meta: { title, tagline } } = useContext(DataContext);
  return (
    <Page>
      <Meta title={`${title} | ${tagline}`} />
      <SectionBanner />
      <SectionONama />
      <SectionSaradnici />
      <SectionKontakt />
      <SectionFooter />
    </Page>
  );
}
