import React from 'react';
import styled, { css } from 'styled-components';

import { ease } from '../../utils';

import Paragraph from './Paragraph';

function getTransition(value) {
  return (
    !value || !value.length
    ? null
    : value.join
    ? value.join(', ')
    : value
  );
}

const LinkHTML = ({
  component: As = 'a',
  transition,
  underline,
  underlineTransition,
  ...props
}) => (
  <Paragraph as={As} {...props} />
);

const Link = styled(LinkHTML)`
  position: relative;
  cursor: pointer;

  transition: ${({ transition }) => getTransition(transition)};

  ${({
    theme,
    underline,
    underlineColor,
    underlineTransition,
  }) => underline && css`
    &:after {
      content: "";
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: -2px;
      border-bottom: 1px solid transparent;
      transform: scaleX(0);
      transform-origin: 0% 50%;
      transition: ${getTransition(underlineTransition)};
    }
    &:hover:after {
      transform: scaleX(1);
      border-bottom: 1px solid ${
        underlineColor || theme.colors.white.shade[1]
      };
    }
  `}
`;

Link.defaultProps = {
  component: 'a',
  transition: [],
  underline: true,
  underlineColor: null,
  underlineTransition: [
    `transform 800ms ${ease('out', 'expo')}`,
    'border-bottom 400ms',
  ],
};

export default Link;
