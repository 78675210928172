/* global */

import React, { useContext, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';

import DataContext from '../Context/Data';
import { Ensure as EnsureGoogleMapsApi } from '../Context/GoogleMapsApi';

import Section from '../Section';
import SiteWidth from '../SiteWidth';
import {
  Wrapper,
  Title,
  Divider,
} from '../Content';

import LayerGoogleMaps from './LayerGoogleMaps';
import GOOGLE_MAPS_STYLES from './google-maps-styles';

import Space from '../Space';

import Info from './Info';
import { Link } from '../Text';

export default function SectionKontakt() {
  const {
    content: {
      sectionKontakt: {
        id,
        title,
        email,
        phoneNumbers,
        address,
      },
    },
  } = useContext(DataContext);
  const { colors } = useTheme();
  const addressHref = `http://www.google.com/maps/search/?api=1&query=${
    address.lat
  },${
    address.lng
  }`;

  const items = useMemo(
    () => ([
      ...phoneNumbers.map(({ number, formatted }) => ({
        id: `phone-number-${number}`,
        label: <PhoneIcon />,
        content: (
          <Link
            href={`tel:${number}`}
            target="_blank"
            underlineColor={colors.primary.base}
          >
            {formatted}
          </Link>
        ),
      })),
      {
        id: 'email',
        label: <MailIcon />,
        content: (
          <Link
            href={`mailto:${email}`}
            target="_blank"
            underlineColor={colors.primary.base}
          >
            {email}
          </Link>
        ),
      },
      {
        id: 'address',
        label: <HomeIcon />,
        content: (
          <Link
            target="_blank"
            underlineColor={colors.primary.base}
            href={addressHref}
          >
            {address.line1}
            <br />
            {address.line2}
          </Link>
        ),
      },
    ]),
    [phoneNumbers, address],
  );

  return (
    <Section id={id}>
      <EnsureGoogleMapsApi>
        <LayerGoogleMaps
          styles={GOOGLE_MAPS_STYLES}
          href={addressHref}
          lng={address.lng}
          lat={address.lat}
        />
      </EnsureGoogleMapsApi>
      <SiteWidth>
        <Wrapper>
          <Title>{title}</Title>
          <Divider />
          <Space size="150px" />
          <Info items={items} />
        </Wrapper>
      </SiteWidth>
    </Section>
  );
}
