import React from 'react';
import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';

import { Link } from './Text';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const LinkStyled = styled(Link)`
  text-align: center;
  color: ${({ theme }) => theme.colors.white.base};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.2em;
  padding: 8px 16px;
  text-transform: uppercase;
  white-space: nowrap;
  border: 2px solid ${({ theme }) => theme.colors.white.base};
  margin: 10px;
  background-color: transparent;
  &:hover {
    background-color: ${({ theme }) => theme.colors.white.shade[3]};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    width: 100%;
  }
`;

const NavigationItem = ({
  id,
  ...props
}) => (
  <LinkStyled
    component={ScrollLink}
    to={id}
    offset={0}
    smooth="easeOutQuart"
    underline={false}
    duration={1000}
    transition={[
      ...Link.defaultProps.transition,
      'background-color 400ms',
    ]}
    {...props}
  />
);

export default function Navigation({ items = [] }) {
  return (
    <Wrapper>
      {items.map(({ id, label }) => (
        <NavigationItem key={id} id={id}>
          {label}
        </NavigationItem>
      ))}
    </Wrapper>
  );
}
