import React, { createContext, useContext, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const Context = createContext();

export function Provider({ children }) {
  const result = useStaticQuery(graphql`
    query Data {
      site {
        siteMetadata {
          title
          tagline
          description
          author
        }
      }
      sectionBanner: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/content/banner.md/" }
        }
      ) {
        edges {
          node {
            id,
            frontmatter {
              navigation { id, label }
            }
          }
        }
      }
      sectionONama: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/content/o-nama.md/" }
        }
      ) {
        edges {
          node { id, html, frontmatter { id, title } }
        }
      }
      sectionSaradnici: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/content/saradnici.md/" }
        }
      ) {
        edges {
          node { id, html, frontmatter { id, title } }
        }
      }
      sectionKontakt: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/content/kontakt.md/" }
        }
      ) {
        edges {
          node {
            id,
            frontmatter {
              id,
              title,
              email,
              phoneNumbers {
                number
                formatted
              }
              address {
                line1
                line2
                lng
                lat
              }
            }
          }
        }
      }
    }
  `);

  const value = useMemo(
    () => {
      const { site, ...content } = result;
      return {
        meta: { lang: 'sr', ...site.siteMetadata },
        content: Object.keys(content).reduce(
          (agr, key) => {
            const {
              edges: [{
                node: { id, html, frontmatter = {} },
              }],
            } = content[key];
            agr[key] = { id, html, ...frontmatter, uid: id };
            return agr;
          },
          {},
        ),
      };
    },
    [result],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
}

export function Debug() {
  const data = useContext(Context);
  return (
    <pre>
      <code>
        {JSON.stringify(data, null, '  ')}
      </code>
    </pre>
  );
}

export default Context;
