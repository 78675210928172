import styled from 'styled-components';

import { antialias, noJitter } from '../../utils';

export default styled.p`
  ${antialias};
  ${noJitter};
  line-height: 1.5;
  max-width: 100%;
  font-weight: 400;
  color: black;
`;
