/* global window, google */

import React, { Component, createRef } from 'react';
import styled, { keyframes } from 'styled-components';

import Layer from '../Layer';

const Positioner = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140%;
  height: 130%;
  top: -28%;
  left: 0px;
`;

const MapContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
`;

const Gradient = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background: ${
    `linear-gradient(${[
      'to right',
      'rgba(255,255,255,0.8) 10%',
      'rgba(255,255,255,0) 70%',
    ].join(', ')})`
  };
`;

const pinPuseAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const Pin = styled.div`
  position: relative;
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary.base};
  &:after {
    content: "";
    display: flex;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.primary.base};
    animation:
      ${pinPuseAnimation}
      5000ms infinite normal
      cubic-bezier(0.165, 0.840, 0.440, 1.000);
  }
`;

const PinButton = styled.a`
  position: relative;
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: transform 200ms cubic-bezier(0.22, 1, 0.36, 1);
  &:hover {
    transform: scale(0.85);
  }
`;

export default class LayerGoogleMaps extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const { lng, lat, styles } = this.props;
      this.map = new google.maps.Map(this.ref.current, {
        center: { lat, lng },
        zoom: 18,
        styles,
        disableDefaultUI: true,
      });
    }
  }

  render() {
    return (
      <Layer>
        <Positioner>
          <MapContainer ref={this.ref} />
          <Gradient />
          <PinButton
            target="_blank"
            href={this.props.href}
          >
            <Pin />
          </PinButton>
        </Positioner>
      </Layer>
    );
  }
}
