import React from 'react';
import styled from 'styled-components';

const DividerHTML = ({
  as: As = 'div',
  color,
  ...props
}) => (
  <As {...props} />
);

const Divider = styled(DividerHTML)`
  margin-top: 15px;
  background-color: ${({ color, theme }) => (
      color === 'dark'
    ? theme.colors.black.shade[0]
    : color === 'light'
    ? theme.colors.white.shade[1]
    : theme.colors.primary.shade[0]
  )};
  width: 60px;
  height: 3px;
`;

export default Divider;
