/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import raf from 'raf';

const STATE = {
  nodes: [],
  handle: null,
  running: false,

  add: (instance) => {
    STATE.nodes.push(instance);
  },

  remove: (instance) => {
    const index = STATE.nodes.indexOf(instance);
    if (index > -1) {
      STATE.nodes.splice(index, 1);
    }
  },

  start: () => {
    if (!STATE.running) {
      STATE.stop();
      STATE.run();
      STATE.running = true;
    }
  },

  stop: () => {
    raf.cancel(STATE.handle);
    STATE.running = false;
  },

  run: () => {
    for (let i = 0; i < STATE.nodes.length; i++) {
      const { state, props } = STATE.nodes[i];
      const { wrapper, target } = state;

      let offset;

      switch (props.align) {
        case 'top':
          offset = wrapper.getBoundingClientRect().top * props.depth * -1;
          break;

        case 'bottom':
          offset = (
            (window.innerHeight
            - (wrapper.getBoundingClientRect().top
            + wrapper.getBoundingClientRect().height))
            * props.depth
          );
          break;

        default: // center
          offset = (
            ((window.innerHeight / 2)
            - (wrapper.getBoundingClientRect().top
            + (wrapper.getBoundingClientRect().height / 2)))
            * props.depth
          );
      }

      if (props.offset) {
        offset += props.offset;
      }

      switch (props.only) {
        case 'negative':
          offset = Math.min(offset, 0);
          break;

        case 'positive':
          offset = Math.max(offset, 0);
          break;

        default:
          offset *= 1;
      }

      target.style.transform = `translate3d(0, ${offset}px, 0)`;
    }

    STATE.handle = raf(STATE.run);
  },
};

export default class Parallax extends Component {
  static defaultProps = {
    depth: 0.5,
    align: 'center',
  }

  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    depth: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    align: PropTypes.oneOf(['center', 'top', 'bottom']),
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    STATE.start();
    STATE.add(this);
  }

  componentWillUnmount() {
    STATE.remove(this);
  }

  render() {
    return (
      <div
        key="wrapper"
        className={this.props.className || 'parallax'}
        style={this.props.style}
        ref={(node) => { this.state.wrapper = node; }}
      >
        <div key="target" ref={(node) => { this.state.target = node; }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
