import styled from 'styled-components';

const SiteWidth = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  max-width: ${({ theme }) => theme.siteWidth[2]};

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-width: ${({ theme }) => theme.siteWidth[1]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    max-width: ${({ theme }) => theme.siteWidth[0]};
  }
`;

export default SiteWidth;
