import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
`;

export default function Info({ items }) {
  return (
    <Wrapper>
      {items.map(item => (
        <Item key={item.id}>
          <Label>
            {item.label}
          </Label>
          <Content>
            {item.content}
          </Content>
        </Item>
      ))}
    </Wrapper>
  );
}
