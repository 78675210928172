import React from 'react';

import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset-advanced';

import { Roboto, Sacramento } from './Fonts';

export const GlobalStyle = createGlobalStyle`
  ${reset};
  body {
    font-size: 18px;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
  }
  *:not(input, textarea, p) {
    &, &:before, &*:after {
      user-select: none;
    }
  }
`;

export default function Page({ children }) {
  return (
    <>
      <Roboto />
      <Sacramento />
      <GlobalStyle />
      {children}
    </>
  );
}
