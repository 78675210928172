import React from 'react';
import { ThemeProvider } from 'styled-components';

import { Provider as DataProvider } from './Context/Data';
import { Provider as GoogleMapsApiProvider } from './Context/GoogleMapsApi';

import theme from '../theme';

export default function App({ children }) {
  return (
    <DataProvider>
      <ThemeProvider theme={theme}>
        <GoogleMapsApiProvider
          apiKey="AIzaSyBPHPpczwbFTslr_oG_iBaW5zSNsR_sfu8"
        >
          {children}
        </GoogleMapsApiProvider>
      </ThemeProvider>
    </DataProvider>
  );
}
