/* global window */
/* eslint-disable react/no-unused-state */

import React, { Component, createContext, useContext } from 'react';

const Context = createContext({ loaded: false });

const SCRIPT_ID = 'GOOGLE_MAPS_API_SCRIPT';
const SCRIPT_CALLBACK = 'GOOGLE_MAPS_API_SCRIPT_LOADED';

export class Provider extends Component {
  constructor(props) {
    super(props);
    this.script = null;
    this.loaded = false;
    this.state = { loaded: this.loaded };
  }

  componentDidMount() {
    if (!this.script) {
      this.script = window.document.getElementById(SCRIPT_ID);
      if (!this.script) {
        this.script = window.document.createElement('script');
        this.script.id = SCRIPT_ID;
        this.script.src = `https://maps.googleapis.com/maps/api/js?${
          [
            this.props.apiKey
            ? `key=${this.props.apiKey}`
            : null,
            this.props.libraries
            ? `libraries=${this.props.libraries}`
            : null,
            `callback=${SCRIPT_CALLBACK}`,
          ]
          .filter(attribute => attribute)
          .join('&')
        }`;
        window[SCRIPT_CALLBACK] = this.handleScriptLoaded;
        window.document.head.append(this.script);
      }
    }
    if (!this.loaded) {
      this.script.addEventListener('load', this.handleScriptLoaded);
    } else {
      this.handleScriptLoaded();
    }
  }

  handleScriptLoaded = () => {
    if (this.script) {
      this.script.removeEventListener('load', this.handleScriptLoaded);
    }
    this.loaded = true;
    this.setState({ loaded: true });
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export function Ensure({ children }) {
  const { loaded } = useContext(Context);
  if (!loaded) {
    return null;
  }
  return children;
}

export default Context;
