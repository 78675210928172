import React from 'react';
import styled from 'styled-components';

import logoSrcS from '../assets/images/logo-s.svg';
import logoSrcM from '../assets/images/logo-m.svg';
import logoSrcL from '../assets/images/logo-l.svg';

const LogoHTML = ({
  width,
  ...props
}) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <img {...props} />
);

const StyledLogo = styled(LogoHTML)`
  width: 100%;
  height: auto;
  max-width: ${({ width }) => width};
`;

export default function Logo({ size, width, ...props }) {
  return (
    <StyledLogo
      src={
          size === 'S'
        ? logoSrcS
        : size === 'M'
        ? logoSrcM
        : size === 'L'
        ? logoSrcL
        : null
      }
      width={
          width
        ? width
        : size === 'L'
        ? '750px'
        : size === 'M'
        ? '400px'
        : null
      }
      data-size={size}
      {...props}
    />
  );
}

Logo.defaultProps = {
  size: 'L',
  width: null,
};
