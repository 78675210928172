import React from 'react';
import styled from 'styled-components';

import { Paragraph } from '../Text';

const TitleHTML = ({
  as: As = Paragraph,
  color,
  ...props
}) => (
  <As {...props} />
);

const Title = styled(TitleHTML)`
  font-size: 42px;
  font-weight: 700;
  color: ${({ theme, color }) => (
      color === 'primary'
    ? theme.colors.primary.base
    : color === 'dark'
    ? theme.colors.black.shade[1]
    : color === 'light'
    ? theme.colors.white.shade[1]
    : color
  )};
`;

Title.defaultProps = {
  color: 'primary',
};

export default Title;
