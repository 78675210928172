import React from 'react';
import styled from 'styled-components';

const LayerHTML = ({
  opacity,
  ...props
}) => (
  <div {...props} />
);

const Layer = styled(LayerHTML)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: ${({ opacity = 1 }) => opacity};
`;

const LayerBackgroundHTML = ({
  background,
  ...props
}) => (
  <Layer {...props} />
);

export const LayerBackground = styled(LayerBackgroundHTML)`
  background: ${({ background }) => background};
`;

const LayerColorHTML = ({
  color,
  ...props
}) => (
  <Layer {...props} />
);

export const LayerColor = styled(LayerColorHTML)`
  background-color: ${({ color }) => color || 'transparent'};
`;

const LayerImageHTML = ({
  color,
  src,
  repeat,
  attachment,
  position,
  size,
  ...props
}) => (
  <Layer {...props} />
);

export const LayerImage = styled(LayerImageHTML)`
  background-color: ${({ color }) => color || 'transparent'};
  background-image: ${({ src }) => `url("${src}")`};
  background-repeat: ${({ repeat }) => repeat || 'no-repeat'};
  background-attachment: ${({ attachment }) => attachment || 'scroll'};
  background-position: ${({ position }) => position || 'center'};
  background-size: ${({ size }) => size || 'cover'};
`;

export default Layer;
