import React from 'react';
import styled, { css } from 'styled-components';

const Space = styled.div`
  display: flex;
  ${({ theme }) => (
    css`
      height: ${theme.contentWrapperPadding[2]};
      @media (max-width: ${theme.breakpoints[1]}) {
        height: ${theme.contentWrapperPadding[1]};
      }
      @media (max-width: ${theme.breakpoints[0]}) {
        height: ${theme.contentWrapperPadding[0]};
      }
    `
  )}
`;

const WrapperHTML = ({
  as: As = 'div',
  direction,
  align,
  justify,
  padding,
  ...props
}) => (
  <As {...props} />
);

const WrapperStyled = styled(WrapperHTML)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  box-sizing: border-box;

  ${({ theme, padding }) => (
      !padding
    ? css`padding: 0px;`
    : padding === true
    ? css`
        padding: ${theme.contentWrapperPadding[2]};
        @media (max-width: ${theme.breakpoints[1]}) {
          padding: ${theme.contentWrapperPadding[1]};
        }
        @media (max-width: ${theme.breakpoints[0]}) {
          padding: ${theme.contentWrapperPadding[0]};
        }
      `
    : css`padding: ${padding};`
  )}
`;

export default function Wrapper({
  opening,
  closing,
  padding,
  children,
  ...props
}) {
  return (
    <WrapperStyled {...props} padding={padding}>
      {
        padding && opening
        ? <Space key="opening" />
        : null
      }
      {children}
      {
        padding && closing
        ? <Space key="closing" />
        : null
      }
    </WrapperStyled>
  );
}

Wrapper.defaultProps = {
  padding: true,
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
};
