import React from 'react';
import styled, { css } from 'styled-components';

const SectionHTML = ({
  fullscreen,
  padding,
  ...props
}) => <div {...props} />;

const Section = styled(SectionHTML)`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${props => props.fullscreen && css`
    height: 100vh;
  `}
`;

export default Section;
