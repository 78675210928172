import React, { useContext } from 'react';

import Helmet from 'react-helmet';

import DataContext from './Context/Data';

export default function Meta({
  title,
  description,
  author,
  lang,
  meta,
  children,
}) {
  const {
    meta: {
      title: globalTitle,
      description: globalDescription,
      author: globalAuthor,
      lang: globalLang,
    },
  } = useContext(DataContext);

  title = title || globalTitle;
  description = description || globalDescription;
  author = author || globalAuthor;
  lang = lang || globalLang;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      meta={[{
        name: 'viewport',
        content: 'width=device-width, initial-scale=1, maximum-scale=1',
      }, {
        name: 'description',
        content: description,
      }, {
        property: 'og:title',
        content: title,
      }, {
        property: 'og:description',
        content: description,
      }, {
        property: 'og:type',
        content: 'website',
      }, {
        name: 'twitter:card',
        content: 'summary',
      }, {
        name: 'twitter:creator',
        content: author,
      }, {
        name: 'twitter:title',
        content: title,
      }, {
        name: 'twitter:description',
        content: description,
      }].concat(meta)}
    >
      {children}
    </Helmet>
  );
}

Meta.defaultProps = {
  meta: [],
};
